import React from 'react';
import { AmountToPay } from '../AmountToPay';
import { Row } from '../Layout';

const CashPayment = ({ transaction, setTransaction }) => (
  <Row>
    <AmountToPay transaction={transaction} setTransaction={setTransaction} />
  </Row>
);

export default CashPayment;
